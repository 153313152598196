.calendar-page {
    position: relative;
    padding: 20px;
    background-color: #ffffff;
    color: #333333;
  }
  
  .header {
     display: flex;
    align-items: center;
    align-self: center;
    direction: rtl;
    color: #333333;
  }
  
  .back-button {
    background: none;
    border: none;
    color: #333333;
    font-size: 8px;
    cursor: pointer;
  }
  
  .back-button:hover {
    text-decoration: underline;
    color: #555555;
  }
  
  .react-calendar__navigation button{
    color: #333333;
  }