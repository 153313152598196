.home-page {
    font-family: Arial, sans-serif;
    padding: 20px;
    color: #292929;
  }
  
  h1 {
    text-align: center;
  }
  
  .category-filter {
    direction: rtl;
    display: flex;
    justify-content: flex-start;
    margin: 20px 0;
    overflow-x: auto;
    padding: 10px;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on touch devices */
  }
  
  /* Hide scrollbar for WebKit browsers (Safari, Chrome) */
  .category-filter::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  .category-filter {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .category-filter button {
    background-color: #737373;
    border: none;
    color: #292929;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
    white-space: nowrap; /* Ensure buttons don't wrap text */
  }
  
  .category-filter button:hover {
    background-color: #eee;
  }
  
  /* Active category button styling */
.category-filter button.active {
    background-color: #eee;
    font-weight: bold;
  }
  .section {
    background: #f4f4f4;
    padding: 10px;
    margin: 20px 0;
    border-radius: 15px;
  }
  
  .error {
    color: red;
    font-weight: bold;
  }
  
  ul {
    list-style-type: none;
    padding: 5px;
    margin: 2px;
  }
  li {
    margin: 5px 0;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #eee;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
  }
  
  /* Hover effect for list items */
  li:hover {
    background-color: #eee;
  }

  
  .category-title {
    padding: 12px;
    margin: 2px;
  }
  
  .service-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
  }
  
  .service-details {
    display: flex;
    justify-content: space-between;
  }
  
  .service-price, .service-duration {
    padding: 5px;
  }
  
  /* Add these new styles */
  .category-section {
    margin-bottom: 30px;
    background: #fff;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .category-title {
    color: #292929;
    font-size: 1.2em;
    margin-bottom: 15px;
    padding: 10px;
    border-bottom: 2px solid #eee;
  }
  
  /* Update existing styles */
  .service-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  
  .service-item {
    margin: 10px 0;
    background: #f8f8f8;
  }
  
  .service-item:hover {
    background-color: #eee;
    transform: translateY(-2px);
    transition: all 0.2s ease;
  }

  .App-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }