.review-page-container {
  position: relative;
  padding: 20px;
}

.review-page {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #eee;
  border-radius: 8px;
}

.review-details {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
  margin: 20px 0;
}

.review-section {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.review-section:last-child {
  border-bottom: none;
}

.review-section h3 {
  color: #333;
  margin-bottom: 10px;
}

.review-section p {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  direction: rtl;
}

/* Add this media query for mobile devices */
@media screen and (max-width: 480px) {
  .review-section p {
    flex-direction: column;
    gap: 4px;
    align-items: center;  /* Add this line */

  }
  
  .review-section p span {
    margin-bottom: 2px;
  }
}

.review-section p span {
  font-weight: bold;
  color: #666;
}

.confirm-button {
  width: 100%;
  padding: 12px;
  background-color: #333333;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.confirm-button:hover {
  background-color: #555555;
} 