.booking-page-container{
  position: relative;
  padding: 20px;
}


.booking-page {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #eee;
    border-radius: 8px;
  }

  
  .booking-form {
    display: flex;
    flex-direction: column;
  }
  
  .booking-form label {
    margin-bottom: 10px;
    color: #333333;
  }
  
  .booking-form input {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333333;
  }
  
  .booking-form button {
    margin-top: 50px;
    padding: 10px;
    background-color: #333333;
    color: #ffffff;
    font-size: large;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .booking-form button:hover {
    background-color: #555555;
  }
  
  .error {
    color: #ff4d4f;
    margin-bottom: 10px;
  }
  

  
  .booking-confirmation h2  {
    color: #333333;
    text-align: center;
  }
  
  .booking-confirmation h4  {
    text-align: center;
    color: #333333;
  }
  
  .booking-confirmation p  {
    display: flex;
    align-items: center;
    justify-self: center;
    direction: rtl;
    color: #333333;
  }
  .booking-confirmation p span {
    color: #666666;

  }


  @media (max-width: 768px) {
    .booking-page {
      max-width: 500px;
      margin: 20px;
      padding: 20px;
      background-color: #eee;
      border-radius: 8px;
    }
  }

  .payment-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }

  .payment-page {
    text-align: center;
  }

  .payment-page h3 {
    margin-bottom: 20px;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .back-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
  }

  .confirmation-details {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 4px;
    margin: 20px 0;
  }

  .confirmation-details p {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
  }

  .confirmation-details p:last-child {
    border-bottom: none;
  }

  .confirmation-details span {
    font-weight: bold;
    margin-left: 10px;
  }

  .booking-confirmation button {
    margin-top: 20px;
    padding: 10px;
    background-color: #333333;
    color: #ffffff;
    font-size: large;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }

  .booking-confirmation button:hover {
    background-color: #555555;
  }

  .booking-error {
    text-align: center;
    padding: 20px;
  }

  .booking-error-actions {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }

  .booking-error-actions button {
    padding: 10px 20px;
    background-color: #333333;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .booking-error-actions button:hover {
    background-color: #555555;
  }