.react-calendar {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #cccccc;
    border-radius: 8px;
    margin: auto;
  }
  
  .react-calendar__tile {
    background-color: #f9f9f9;
    color: #333333;
    border-radius: 4px;
    border: 3px solid #dddddd;
  }
  
  .react-calendar__tile--active {
    background-color: #e0e0e0;
    color: #333333;
  }
  
  .react-calendar__tile--now {
    background-color: #e0e0e0;
    color: #333333;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #aaaaaa;
  }
  
  
  .time-slots {
    margin-top: 20px;
  }
  
  .time-slots h3 {
    color: #333333;
  }
  
  .time-slots ul {
    list-style-type: none;
    padding: 0;
  }
  
  .time-slots li {
    background-color: #f0f0f0;
    color: #333333;
    padding: 10px;
    margin: 5px 0;
    border-radius: 4px;
    justify-content: center;

  }

  .available-slots-calendar {
    background-color: #ffffff;
    color: #333333;
    padding: 20px;
    border-radius: 8px;
  }
  
  .available-slots-calendar .error {
    color: #ff4d4f;
  }
  
  .time-slots {
    margin-top: 16px;
  }
  
  .time-slots h3 {
    margin-bottom: 8px;
  }
  
  .time-slots ul {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 50% grid layout */
    gap: 8px; /* Adjust the gap between grid items as needed */
    padding: 0;
    list-style-type: none;
  }
  
  .time-slot-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .time-slot-item button {
    margin-left: 8px;
  }
  .available-slots-calendar {
    background-color: #eee;
    color: #ffffff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .available-slots-calendar .error {
    color: #ff4d4f;
  }
  
  .time-slots {
    margin-top: 20px;
  }
  
  .time-slots h3 {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .time-slots h4 .calendar-icon {
    margin-right: 10px;
  }
  
  .time-slot-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .time-slot-item button {
    background-color: #f0f0f0;
    color: #333333;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .time-slot-item button:hover {
    background-color: #cccccc;
  }